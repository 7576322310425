import React from "react";
import Layout from '../components/layout';
import Title from '../components/title';

export default () => (
<Layout>
    <Title text="Contact Us" />
    <p>Get in touch and we'll get back to you as soon as we can.  We look forward to hearing from you!</p>
</Layout>
)
